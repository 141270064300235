// @ts-nocheck
import { z } from "zod";
export const formatErrors = (
  /** @type {import('zod').ZodFormattedError<Map<string,string>,string>} */
  errors
) =>
  Object.entries(errors)
    .map(([name, value]) => {
      if (value && "_errors" in value) {
        return `${name}: ${value._errors.join(", ")}\n`;
      }
    })
    .filter(Boolean);

/**
 * Specify your server-side environment variables schema here.
 * This way you can ensure the app isn't built with invalid env vars.
 */
export const serverSchema = z.object({
  // App
  NODE_ENV: z.enum(["development", "test", "production"]),
  APP_ENV: z.enum(["preview", "production"]),
  // encrypt session cookie with this
  SESSION_COOKIE_PASSWORD: z.string(),
  // User login location
  CHAR_SERVER_URL: z.string(),
  NEO_HOST: z.string(),
  CF_PAGES_URL: z.string().default("http://localhost:3000"),
  // service passwords to access CHAR server, for authentication
  CF_ACCESS_CLIENT_ID: z.string(),
  CF_ACCESS_CLIENT_SECRET: z.string(),
  ANNOTATION_SVC_HOST_BASE: z.string(),
  ANNOTATION_TASK_CREATION_API_KEY: z.string(),
});

/**
 * You can't destruct `process.env` as a regular object in the Next.js
 * middleware, so you have to do it manually here.
 * @type {{ [k in keyof z.infer<typeof serverSchema>]: z.infer<typeof serverSchema>[k] | undefined }}
 */
export const serverEnv = {
  NODE_ENV: process.env.NODE_ENV,
  APP_ENV: process.env.NEXT_PUBLIC_APP_ENV,
  CHAR_SERVER_URL: process.env.NEXT_PUBLIC_CHAR_SERVER_URL,
  SESSION_COOKIE_PASSWORD: process.env.SESSION_COOKIE_PASSWORD,
  CF_PAGES_URL: process.env.CF_PAGES_URL,
  NEO_HOST: process.env.NEXT_PUBLIC_NEO_HOST_BASE,
  CF_ACCESS_CLIENT_ID: process.env.CF_ACCESS_CLIENT_ID,
  CF_ACCESS_CLIENT_SECRET: process.env.CF_ACCESS_CLIENT_SECRET,
  ANNOTATION_SVC_HOST_BASE: process.env.NEXT_PUBLIC_ANNOTATION_SVC_HOST_BASE,
  ANNOTATION_TASK_CREATION_API_KEY:
    process.env.ANNOTATION_TASK_CREATION_API_KEY,
};

/**
 * Specify your client-side environment variables schema here.
 * This way you can ensure the app isn't built with invalid env vars.
 * To expose them to the client, prefix them with `NEXT_PUBLIC_`.
 */
export const clientSchema = z.object({
  NEXT_PUBLIC_APP_ENV: z.string(),
  NEXT_PUBLIC_ANNOTATION_SVC_HOST_BASE: z.string(),
  NEXT_PUBLIC_CHAR_SERVER_URL: z.string(),
  NEXT_PUBLIC_NEO_HOST_BASE: z.string(),
});

/**
 * You can't destruct `process.env` as a regular object, so you have to do
 * it manually here. This is because Next.js evaluates this at build time,
 * and only used environment variables are included in the build.
 * @type {{ [k in keyof z.infer<typeof clientSchema>]: z.infer<typeof clientSchema>[k] | undefined }}
 */
export const clientEnv = {
  NEXT_PUBLIC_APP_ENV: process.env.NEXT_PUBLIC_APP_ENV,
  NEXT_PUBLIC_CHAR_SERVER_URL: process.env.NEXT_PUBLIC_CHAR_SERVER_URL,
  NEXT_PUBLIC_NEO_HOST_BASE: process.env.NEXT_PUBLIC_NEO_HOST_BASE,
  NEXT_PUBLIC_ANNOTATION_SVC_HOST_BASE:
    process.env.NEXT_PUBLIC_ANNOTATION_SVC_HOST_BASE ??
    "annotation-stage.characterai.dev",
};
