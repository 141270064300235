"use client";

import { DefaultApi, Configuration } from "@/client_autogen/index";
import { ClientEnv } from "@src/env/client";

let apiClient: DefaultApi | null = null;

// Client-side Annotation API client
export function createClient(accessToken: string): DefaultApi {
  if (apiClient != null) {
    return apiClient;
  }

  const annotationSvcBaseUrl =
    ClientEnv.NEXT_PUBLIC_ANNOTATION_SVC_HOST_BASE.includes("localhost")
      ? `http://${ClientEnv.NEXT_PUBLIC_ANNOTATION_SVC_HOST_BASE}/v1`
      : `https://${ClientEnv.NEXT_PUBLIC_ANNOTATION_SVC_HOST_BASE}/v1`;
  const config: Configuration = {};
  function configuredFetch(
    input: RequestInfo | URL,
    init?: RequestInit | undefined
  ) {
    return fetch(input, {
      ...(init || {}),
      headers: {
        authorization: `Token ${accessToken}`,
      },
    });
  }
  apiClient = new DefaultApi(config, annotationSvcBaseUrl, configuredFetch);
  return apiClient;
}

let _annotationClient: DefaultApi = null as unknown as DefaultApi;

export const setAnnotationClient = (client: DefaultApi) => {
  _annotationClient = client;
};

export const getAnnotationClient = () => _annotationClient;
