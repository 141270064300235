"use client";

import "@src/setup/clientCommon";

import { setupAxios } from "@src/lib/axios";
import { SetChatProviderUser } from "@character-tech/common/src/chatManager/chatProviderUser";
import { setupAxios as setupClientCommonAxios } from "@character-tech/common/src/lib/axios";
import { hiddenCharacters } from "@character-tech/common/src/state/signals";
import type { Participant } from "@character-tech/common/src/types/app-api";
import axios from "axios";
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  type ReactNode,
} from "react";
import { UseQueryProvider } from "../UseQueryProvider";
import {
  createClient,
  setAnnotationClient,
} from "@src/services/experts/client";
import { setUser } from "@sentry/nextjs";

export interface AuthContextType {
  user: Participant | null;
  token: string;
  isLoading: boolean;
  isStaff: boolean;
  setUser: (user: Participant) => void;
}

export const AuthContext = createContext<AuthContextType>({
  user: null,
  isLoading: true,
  token: "",
  isStaff: false,
  setUser: () => {},
});

export const useAuth = () => useContext(AuthContext);

const updateSentryUser = (user: Participant | null) => {
  const userID = user?.user?.id;
  const email = user?.email;
  if (user === null) {
    setUser(null);
  } else {
    setUser({
      userID,
      email,
    });
  }
};

export const AuthProvider: React.FC<{
  children: ReactNode;
  user: AuthContextType["user"];
  token?: string;
  // eslint-disable-next-line react/function-component-definition
}> = ({ children, user, token = "" }) => {
  setupClientCommonAxios(() => axios);
  if (user) {
    SetChatProviderUser(user);
    updateSentryUser(user);
  }
  if (token) {
    setupAxios(token);
    setAnnotationClient(createClient(token));
  }

  const [localUser, setLocalUser] = useState<AuthContextType["user"]>(user);

  const providerValue = useMemo(
    () => ({
      user: localUser,
      token,
      isLoading: false,
      setUser: setLocalUser,
      isStaff: !!user?.user?.is_staff,
    }),
    [localUser, token, user?.user?.is_staff]
  );

  useEffect(() => {
    hiddenCharacters.value = user?.hidden_characters ?? [];
  }, [user]);

  return (
    <AuthContext.Provider value={providerValue}>
      <UseQueryProvider>{children}</UseQueryProvider>
    </AuthContext.Provider>
  );
};
