"use client";

import { clientEnv, clientSchema, formatErrors } from "./schema";

const parsedClientEnv = clientSchema.safeParse(clientEnv);

if (!parsedClientEnv.success) {
  console.error(
    "❌ Invalid client environment variables:\n",
    ...formatErrors(parsedClientEnv.error.format())
  );
  throw new Error(
    `Invalid client environment variables: ${JSON.stringify(
      parsedClientEnv.error.format(),
      null,
      2
    )}`
  );
}

for (const key of Object.keys(parsedClientEnv.data)) {
  if (!key.startsWith("NEXT_PUBLIC_")) {
    console.warn(
      `❌ Invalid public environment variable name: ${key}. It must begin with 'NEXT_PUBLIC_'`
    );

    throw new Error("Invalid public environment variable name");
  }
}

export const ClientEnv = parsedClientEnv.data;

export const isProductionEnv = () => {
  return ClientEnv.NEXT_PUBLIC_APP_ENV === "production";
};
